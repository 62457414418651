$(document).on('click', '#carousel-list .carousel-list-item', setCarouselIndex);
$(document).on('click', '#galery-carousel-left', prevGalery);
$(document).on('click', '#galery-carousel-right', nextGalery);
/////////////////////////////////////
var carouselArray = [];
var carouselIndex = 0;
var companyGaleryArray = [];
var carouselInterval = setInterval(nextCarousel, 60000);
$(document).ready(function(){
    getCarousel();
    getGalery();
    getClients();
    ////////Company action buttons
    $('#about-us-list .about-us-list-item').hover(aboutUsHover, aboutUsLeave);
});
function getCarousel(){
    GetMethodFunction('/WebPage/HomeCarrousel/Get', null, showCarousel, null);
}
function showCarousel(response){
    carouselArray = response.data;
    let AppendContent = '';
    for (let index = 0; index < carouselArray.length; index++) {
        AppendContent += '<li class="carousel-list-item" id="carousel-list-item-'+index+'" value="'+index+'"></li>';
    }
    $('#carousel-list').empty().append(AppendContent);
    showCarouselIndex();
}
function nextCarousel(){
    carouselIndex++;
    if(carouselIndex >= carouselArray.length){
        carouselIndex = 0;
    }
    showCarouselIndex();
}
function prevCarousel(){
    carouselIndex--;
    if(carouselIndex < 0){
        carouselIndex = carouselArray.length - 1;
    }
    showCarouselIndex();
}
function setCarouselIndex(){
    clearInterval(carouselInterval);
    carouselIndex = $(this).attr('value');
    showCarouselIndex();
}
function showCarouselIndex(){
    var carousel = carouselArray[carouselIndex];
    if(carousel.title == null || carousel.title == ''){
        $('#carousel-text-title').css('display', 'none');
    }else{
        $('#carousel-text-title').css('display', 'block');
        $('#carousel-text-title').text(carousel.title);
    }
    if(carousel.subtitle == null || carousel.subtitle == ''){
        $('#carousel-text-subtitle').css('display', 'none');
    }else{
        $('#carousel-text-subtitle').css('display', 'block');
        $('#carousel-text-subtitle').text(carousel.subtitle);
    }
    if(carousel.button_text == null || carousel.button_text == ''){
        $('#carousel-text-action').css('display', 'none');
    }else{
        $('#carousel-text-action').css('display', 'block');
        $('#carousel-text-action').text(carousel.button_text);
        $('#carousel-text-action').attr('href', carousel.button_url);
    }
    if(carousel.image_position == 0){
        $('#carousel-image-container').css('background-image', 'url(' + carousel.path + ')');
        $('#carousel-container').css('background-image', 'url(/images/carousel-background.png)');
        $('#carousel-container').css('background-size', '60vw');
    }else{
        $('#carousel-image-container').css('background-image', 'none');
        $('#carousel-container').css('background-image', 'url(' + carousel.path + ')');
        $('#carousel-container').css('background-size', 'cover');
    }
    
    $('#carousel-list .carousel-list-item').removeClass('active');
    $('#carousel-list-item-'+carouselIndex).addClass('active');
}


function aboutUsHover(){
    $(this).find('.about-us-img').css('display', 'none');
    $(this).find('.about-us-title').css('display', 'none');
    $(this).find('.about-us-paragraph').css('display', 'block');
}
function aboutUsLeave(){
    $(this).find('.about-us-img').css('display', 'block');
    $(this).find('.about-us-title').css('display', 'block');
    $(this).find('.about-us-paragraph').css('display', 'none');
}
function getGalery(){
    GetMethodFunction('/WebPage/Company/Galery/Get', null, showGalery, null);
}
function showGalery(response){
    companyGaleryArray = response.data;
    setGaleryImages();
}
function setGaleryImages(){
    if(companyGaleryArray[0]!=null) $('#galery-carousel-list .galery-carousel-list-item-0').css('background-image', 'url('+companyGaleryArray[0].path+')');
    if(companyGaleryArray[1]!=null) $('#galery-carousel-list .galery-carousel-list-item-1').css('background-image', 'url('+companyGaleryArray[1].path+')');
    if(companyGaleryArray[2]!=null) $('#galery-carousel-list .galery-carousel-list-item-2').css('background-image', 'url('+companyGaleryArray[2].path+')');
    if(companyGaleryArray[3]!=null) $('#galery-carousel-list .galery-carousel-list-item-3').css('background-image', 'url('+companyGaleryArray[3].path+')');
    if(companyGaleryArray[4]!=null) $('#galery-carousel-list .galery-carousel-list-item-4').css('background-image', 'url('+companyGaleryArray[4].path+')');
    
}
function nextGalery(){
    let last = companyGaleryArray.length - 1;
    let temp = companyGaleryArray[last];
    for (let index = last; index > 0; index--) {
        companyGaleryArray[index] = companyGaleryArray[index - 1];
    }
    companyGaleryArray[0] = temp;
    setGaleryImages();
}
function prevGalery(){
    let temp = companyGaleryArray[0];
    for (let index = 0; index < companyGaleryArray.length - 1; index++) {
        companyGaleryArray[index] = companyGaleryArray[index + 1];
    }
    companyGaleryArray[companyGaleryArray.length - 1] = temp;
    setGaleryImages();
}
function getClients(){
    GetMethodFunction('/WebPage/Client/GetAll', null, showClients, null);
}
function showClients(response){
    let clients = response.clients;
    let AppendContent = '';
    if(clients[0] != null) AppendContent += '<img src="'+clients[0].path+'" title="'+clients[0].name+'" id="clients-images-1" alt="client-1" class="clients-images">';
    if(clients[1] != null) AppendContent += '<img src="'+clients[1].path+'" title="'+clients[1].name+'" id="clients-images-2" alt="client-2" class="clients-images">';
    if(clients[2] != null) AppendContent += '<img src="'+clients[2].path+'" title="'+clients[2].name+'" id="clients-images-3" alt="client-3" class="clients-images">';
    if(clients[3] != null) AppendContent += '<img src="'+clients[3].path+'" title="'+clients[3].name+'" id="clients-images-4" alt="client-4" class="clients-images">';
    if(clients[4] != null) AppendContent += '<img src="'+clients[4].path+'" title="'+clients[4].name+'" id="clients-images-5" alt="client-5" class="clients-images">';
    if(clients[5] != null) AppendContent += '<img src="'+clients[5].path+'" title="'+clients[5].name+'" id="clients-images-6" alt="client-6" class="clients-images">';
    if(clients[6] != null) AppendContent += '<img src="'+clients[6].path+'" title="'+clients[6].name+'" id="clients-images-7" alt="client-7" class="clients-images">';
    if(clients[7] != null) AppendContent += '<img src="'+clients[7].path+'" title="'+clients[7].name+'" id="clients-images-8" alt="client-8" class="clients-images">';
    if(clients[8] != null) AppendContent += '<img src="'+clients[8].path+'" title="'+clients[8].name+'" id="clients-images-9" alt="client-9" class="clients-images">';
    if(clients[9] != null) AppendContent += '<img src="'+clients[9].path+'" title="'+clients[9].name+'" id="clients-images-10" alt="client-10" class="clients-images">';
    if(clients[10] != null) AppendContent += '<img src="'+clients[10].path+'" title="'+clients[10].name+'" id="clients-images-11" alt="client-11" class="clients-images">';
    if(clients[11] != null) AppendContent += '<img src="'+clients[11].path+'" title="'+clients[11].name+'" id="clients-images-12" alt="client-12" class="clients-images">';
    if(clients[12] != null) AppendContent += '<img src="'+clients[12].path+'" title="'+clients[12].name+'" id="clients-images-13" alt="client-13" class="clients-images">';
    if(clients[13] != null) AppendContent += '<img src="'+clients[13].path+'" title="'+clients[13].name+'" id="clients-images-14" alt="client-14" class="clients-images">';
    if(clients[14] != null) AppendContent += '<img src="'+clients[14].path+'" title="'+clients[14].name+'" id="clients-images-15" alt="client-15" class="clients-images">';
    $('#clients-images-container').empty().html(AppendContent);
}